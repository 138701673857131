/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
import axios from 'axios';
import firebase from 'firebase';
import { ConnectedFocusError } from 'focus-formik-error';
import { Form, Formik } from 'formik';
import { graphql, navigate } from 'gatsby';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import * as Yup from 'yup';
import { defaultLanguage } from '../../prismic-configuration';
import {
  MessageInput,
  MyCheckbox,
  TextInput,
} from '../components/Form-components/InputFields-components';
import RequiredAlert from '../components/Form-components/RequiredAlert';
import { Layout } from '../components/Layout';
import { Seo } from '../components/Seo';
import { SliceZone } from '../components/SliceZone';
import { storage } from '../firebase';
import { linkResolver } from '../utils/LinkResolver';
// If you were requested to make changes, please, click the button below: Edit My Portfolio.
function LocalForm({ field_type, title, required, name, description, placeholder, form_type }) {
  if (field_type === 'Long Input') {
    return (
      <h5 key={title} className=" mb-4">
        {title}
        {required && <span className="text-danger"> *</span>}
        {description && <p className="mb-0 mt-1">{description}</p>}

        <MessageInput
          name={name || 'enter_name_variable'}
          type={form_type || 'enter_form_type'}
          placeholder={placeholder || 'enter_placeholder'}
        />
      </h5>
    );
  }
  if (field_type === 'One line Input') {
    return (
      <h5 key={name} className=" mb-4">
        {title}
        {required && <span className="text-danger"> *</span>}
        {description && <p className="mb-0 mt-1">{description}</p>}

        <TextInput
          name={name || 'enter_name_variable'}
          type={form_type || 'enter_form_type'}
          placeholder={placeholder || 'placeholder'}
        />
      </h5>
    );
  }
}
function PageTemplate({ data }) {
  const [status, setStatus] = useState('initial');
  const [progress, setProgress] = useState(0);
  const [pdfDocUrl, setPdfDocUrl] = useState('');
  const [alert_visibility, setAlert_visibility] = useState('invisible');
  const [visibility_b1, setVisibility_b1] = useState('d-block');
  const [visibility_b2, setVisibility_b2] = useState('d-none');
  const [visibility_b3, setVisibility_b3] = useState('d-none');
  const [currentUserProfileData, setCurrentUserProfileData] = useState([]);
  const [portfolio_complete, setPortfolio_complete] = useState(false);
  const isBrowser = typeof window !== 'undefined';

  // fetch if exist Firebase firestore(database) data
  useEffect(() => {
    let isMounted = true;
    if (isBrowser && isMounted) {
      try {
        firebase
          .firestore()
          .collection('usersCollection')
          .onSnapshot(snapshot => {
            const listUsers = snapshot.docs.map(doc => ({
              id: doc.id,
              ...doc.data(),
            }));
            const usersPersonalData = listUsers.filter(
              userFromList => userFromList.uid === firebase.auth().currentUser?.uid,
            );
            setCurrentUserProfileData(usersPersonalData[0]);
            setPortfolio_complete(
              usersPersonalData[0]?.portfolio_complete === undefined
                ? false
                : usersPersonalData[0]?.portfolio_complete,
            );
          });
      } catch (error) {
        console.log(error);
      }
    }
    return () => {
      isMounted = false;
      if (isBrowser && isMounted) {
        try {
          firebase
            .firestore()
            .collection('usersCollection')
            .onSnapshot(snapshot => {});
        } catch (error) {
          console.log(error);
        }
      }
    };
  }, [isBrowser]);

  const userId = currentUserProfileData?.id;
  const userName = currentUserProfileData?.full_name;
  const userRequestID = currentUserProfileData?.requestID;
  let firestore = {};
  if (isBrowser) {
    firestore = firebase.firestore();
  }

  if (!data) return null;
  const pageContent = data.prismicPage;
  const page = pageContent.data || {};

  const { lang, type, url } = pageContent;
  const alternateLanguages = pageContent.alternate_languages || [];
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  };
  const topMenu = data.prismicMenuLink || {};
  const personalPortfolioPage = data.prismicPage || {};

  const firstForm = personalPortfolioPage?.data?.body[1] || {};
  const secondForm = personalPortfolioPage?.data?.body[2] || {};
  const firstFormItems = firstForm?.items || {};
  const secondFormItems = secondForm?.items || {};
  const startButton = firstForm?.primary?.start__button_laber || 'startButton';
  const saveButton = firstForm?.primary?.save__button_laber || 'saveButton';
  const continueButton = firstForm?.primary?.next__button_label || 'continueButton';
  const prevButton = firstForm?.primary?.previous__button_label || 'prevButton';
  const submitButton = firstForm?.primary?.proceed_to_the_payment__button_label || 'submitButton';
  const pleaseDescribeMessage =
    secondForm?.primary?.privacy_message_starter ||
    'Please describe if you have achieved the competencies in the following domains!';
  const privacyStartStatement =
    firstForm?.primary?.privacy_message_starter || 'privacyStartStatement';
  const privacyStatement = firstForm?.primary?.privacy_message || 'privacyButton';
  const privacyLink = firstForm?.primary?.privacy_page_link?.uid || 'privacy';

  const localLinkResolver = lang === defaultLanguage ? '' : `/${lang}`;
  const isDisabled = status === 'pending';

  // First and Second Form Initial Values
  const initialValues = currentUserProfileData || {
    courses: '',
    committees_membership: '',
    pdp: '',
    conferences: '',
    higher_education_institution: '',
    pedagogical_experience_other: '',
    internship: '',
    professional_associations_membership: '',
    researcher_ID: '',
    awards: '',
    philosophy: '',
    ///
    innovations: '',
    time_managment: '',
    effective_interaction: '',
    learning_interactivity: '',
    systems_analysis: '',
    psychology_communication: '',
    interaction: '',
    sustainable_development: '',
    digital_education: '',
    problem_project_practice_oriented_learning: '',
    outcomes_assessment: '',
    course_design: '',
    innovation_process: '',
    lifelong_learning: '',
    facebook_link: '',
    linkedin_link: '',
    instagram_link: '',
    twitter_link: '',
    personal_website_link: '',
    to_be_reviewed: false,
    acceptedTerms: false,
  };
  // First Form
  const firstFormValidationSchema = Yup.object().shape({
    courses: Yup.string().required('Required'),
    committees_membership: Yup.string().required('Required'),
    pdp: Yup.string().required('Required'),
    conferences: Yup.string().required('Required'),
    pedagogical_experience_other: Yup.string(),
    internship: Yup.string(),
    professional_associations_membership: Yup.string(),
    researcher_ID: Yup.string(),
    awards: Yup.string(),
    philosophy: Yup.string(),
  });

  // Personal Portfolio First Form User Data Update
  const firstFormSaveData = async values => {
    try {
      await firestore
        .collection('usersCollection')
        .doc(userId)
        .update({
          courses: values.courses || '',
          committees_membership: values.committees_membership || '',
          pdp: values.pdp || '',
          conferences: values.conferences || '',
          pedagogical_experience_other: values.pedagogical_experience_other || '',
          internship: values.internship || '',
          professional_associations_membership: values.professional_associations_membership || '',
          researcher_ID: values.researcher_ID || '',
          awards: values.awards || '',
          philosophy: values.philosophy || '',
          to_be_reviewed: false,
          portfolio_complete: false,
        });
      alert('Your changes have been saved');
    } catch (error) {
      console.log(error);
    }
  };

  const handleFirstFormSubmit = async (values, actions) => {
    if (
      values.courses !== undefined &&
      values.committees_membership !== undefined &&
      values.pdp !== undefined &&
      values.conferences !== undefined
    ) {
      firstFormSaveData(values);
      setVisibility_b1('d-none');
      setVisibility_b2('d-none');
      setVisibility_b3('d-block');
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
      actions.setSubmitting(false);
    } else {
      setAlert_visibility('visible');
      setTimeout(() => {
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }, 2000);
      setTimeout(() => {
        setAlert_visibility('invisible');
      }, 4000);
    }
  };

  // Second Form Save User Data
  const secondFormSaveData = async values => {
    await firestore
      .collection('usersCollection')
      .doc(userId)
      .update({
        innovations: values.innovations || '',
        time_managment: values.time_managment || '',
        effective_interaction: values.effective_interaction || '',
        learning_interactivity: values.learning_interactivity || '',
        systems_analysis: values.systems_analysis || '',
        psychology_communication: values.psychology_communication || '',
        interaction: values.interaction || '',
        sustainable_development: values.sustainable_development || '',
        digital_education: values.digital_education || '',
        problem_project_practice_oriented_learning:
          values.problem_project_practice_oriented_learning || '',
        outcomes_assessment: values.outcomes_assessment || '',
        course_design: values.course_design || '',
        innovation_process: values.innovation_process || '',
        lifelong_learning: values.lifelong_learning || '',
        facebook_link: values.facebook_link || '',
        linkedin_link: values.linkedin_link || '',
        instagram_link: values.instagram_link || '',
        twitter_link: values.twitter_link || '',
        personal_website_link: values.personal_website_link || '',
        portfolio_file: pdfDocUrl,
        portfolio_complete: false,
        to_be_reviewed: false,
        registered_on_date: '',
      });
    alert('Your changes have been saved');
  };
  const secondFormSaveDataOnSubmit = async values => {
    await firestore
      .collection('usersCollection')
      .doc(userId)
      .update({
        innovations: values.innovations || '',
        time_managment: values.time_managment || '',
        effective_interaction: values.effective_interaction || '',
        learning_interactivity: values.learning_interactivity || '',
        systems_analysis: values.systems_analysis || '',
        psychology_communication: values.psychology_communication || '',
        interaction: values.interaction || '',
        sustainable_development: values.sustainable_development || '',
        digital_education: values.digital_education || '',
        problem_project_practice_oriented_learning:
          values.problem_project_practice_oriented_learning || '',
        outcomes_assessment: values.outcomes_assessment || '',
        course_design: values.course_design || '',
        innovation_process: values.innovation_process || '',
        lifelong_learning: values.lifelong_learning || '',
        facebook_link: values.facebook_link || '',
        linkedin_link: values.linkedin_link || '',
        instagram_link: values.instagram_link || '',
        twitter_link: values.twitter_link || '',
        personal_website_link: values.personal_website_link || '',
        portfolio_file: pdfDocUrl,
        portfolio_complete: true,
        to_be_reviewed: true,
        currentStatus: 'Portfolio under review',
        registered_on_date: new Date().toLocaleString(),
      });
    // sent message to the client
    // sent message to Secretary
    alert('Your changes have been saved');
  };

  const handleSecondFormSubmitRequest = async (values, actions) => {
    if (progress === 100 && values.acceptedTerms === true) {
      setVisibility_b1('d-none');
      setVisibility_b2('d-none');
      setVisibility_b3('d-none');
      secondFormSaveDataOnSubmit(values);
      actions.setSubmitting(false);

      try {
        // Send data to the function,
        // await the result.
        await axios.post('/api/send-message-on-request', {
          userName,
          userRequestID,
          userEmail: currentUserProfileData.email,
        });
        navigate(`${localLinkResolver}/`);
      } catch (error) {
        alert(`Something went wrong => ${error}`);
        setStatus('failed');
      }
    } else {
      setAlert_visibility('visible');
      setTimeout(() => {
        setAlert_visibility('invisible');
      }, 4000);
    }
  };

  const clearFileInput = e => {
    e.target.previousSibling.value = '';
    setProgress(0);
    setPdfDocUrl('');
  };

  const clearFileInputKey = e => {
    if (e.key === 'Enter') {
      e.target.previousSibling.value = '';
      setProgress(0);
      setPdfDocUrl('');
    }
  };

  const handleFileUpload = e => {
    const pdfDoc = e.target.files[0] ? e.target.files[0] : undefined;
    if (pdfDoc) {
      const uploadTask = storage.ref(`documents/${pdfDoc.name}`).put(pdfDoc);
      uploadTask.on(
        'state_changed',
        snapshot => {
          const snapshotProgress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100,
          );
          setProgress(snapshotProgress);
        },
        error => {
          console.log(error);
        },
        () => {
          storage
            .ref('documents')
            .child(pdfDoc.name)
            .getDownloadURL()
            .then(storageUrl => {
              setPdfDocUrl(storageUrl);
            });
        },
      );
    } else {
      alert('No File was selected, please try again');
    }
  };
  // Your portfolio is submitted.
  // If you were not contacted by the Enter Secretariat to make changes, just get back to your Personal Area.

  return (
    <Layout topMenu={topMenu.data} activeDocMeta={activeDoc}>
      <Seo title={page.page_title.text} lang={lang} />
      <h1 className="page-title">{page.page_title.text}</h1>
      {portfolio_complete && (
        <>
          <p className="px-4 mx-auto">Your portfolio is submitted</p>
          <a
            // to={`${localLinkResolver}/enter-educators/full-portfolio-page?userId=${userUnderReview.id}`}
            href={`${localLinkResolver}/enter-educators/full-portfolio-page?userId=${userId}`}
            className="btn btn-outline-info px-4 mx-auto"
            rel="noreferrer noopener"
            target="_blank"
          >
            View Portfolio
          </a>
          <hr style={{ width: '50%', margin: '20px auto' }} />
          <p className="px-4 mx-auto">
            If you were not contacted by the Enter Secretariat to make changes, just get back to
            your Personal Area.
          </p>
          <p className="px-4 mx-auto">
            If you were requested to make changes, please, click the button below:
          </p>
          <button
            type="button"
            className="btn btn-outline-info px-4 mx-auto"
            style={{ width: '350px', margin: '0, auto' }}
            onClick={() => {
              setPortfolio_complete(false);
            }}
          >
            Edit My Portfolio
          </button>
        </>
      )}
      {!portfolio_complete && (
        <>
          <Container className={`personal-portfolio block-one ${visibility_b1}`}>
            <span className="btn btn-outline-primary btn-lg disabled mx-auto d-flex justify-content-center mb-5">
              {' '}
              Step 1 of 3
            </span>
            <SliceZone sliceZone={page.body} />
            <div className="d-flex">
              <button
                className="pp-button"
                type="button"
                onClick={() => {
                  setPortfolio_complete(true);
                }}
              >
                Back
              </button>
              <button
                onClick={() => {
                  setVisibility_b1('d-none');
                  setVisibility_b2('d-block');
                  setVisibility_b3('d-none');
                  document.body.scrollTop = 0;
                  document.documentElement.scrollTop = 0;
                }}
                id="go_to_step_2"
                className="pp-button"
                type="button"
              >
                {startButton}
              </button>
            </div>
          </Container>
          <Container className={`personal-portfolio block-two ${visibility_b2}`}>
            <span className="btn btn-outline-primary btn-lg disabled mx-auto d-flex justify-content-center mb-5">
              {' '}
              Step 2 of 3
            </span>
            {/* First Form */}
            <Formik
              enableReinitialize
              initialValues={initialValues}
              isSubmitting
              validationSchema={firstFormValidationSchema}
              validateOnChange
              onSubmit={handleFirstFormSubmit}
            >
              {props => (
                <Form className="main-form">
                  <ConnectedFocusError />
                  {firstFormItems.map(f => LocalForm(f))}
                  <RequiredAlert alert_visibility={alert_visibility} />
                  <div className="d-flex flex-row mt-2">
                    <button
                      onClick={() => {
                        firstFormSaveData(props.values);
                        setVisibility_b1('d-block');
                        setVisibility_b2('d-none');
                        setVisibility_b3('d-none');
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                      }}
                      id="go_to_step_2"
                      type="button"
                    >
                      {prevButton}
                    </button>
                    <button
                      onClick={() => {
                        firstFormSaveData(props.values);
                      }}
                      id="go_to_step_2"
                      type="button"
                    >
                      {saveButton}
                    </button>
                    <button disabled={isDisabled} id="submit" type="submit">
                      {continueButton}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Container>
          <Container className={`personal-portfolio block-three ${visibility_b3}`}>
            <span className="btn btn-outline-primary btn-lg disabled mx-auto d-flex justify-content-center mb-5">
              {' '}
              Step 3 of 3
            </span>
            <span className="btn btn-outline-success btn-lg disabled mx-auto d-flex justify-content-center mb-5">
              {pleaseDescribeMessage}
            </span>
            {/* Second Form */}
            <Formik
              enableReinitialize
              initialValues={initialValues}
              isSubmitting
              validationSchema={Yup.object().shape({
                innovations: Yup.string().required('Required'),
                time_managment: Yup.string().required('Required'),
                effective_interaction: Yup.string().required('Required'),
                learning_interactivity: Yup.string().required('Required'),
                systems_analysis: Yup.string().required('Required'),
                psychology_communication: Yup.string().required('Required'),
                interaction: Yup.string().required('Required'),
                sustainable_development: Yup.string().required('Required'),
                digital_education: Yup.string().required('Required'),
                problem_project_practice_oriented_learning: Yup.string().required('Required'),
                outcomes_assessment: Yup.string().required('Required'),
                course_design: Yup.string().required('Required'),
                innovation_process: Yup.string().required('Required'),
                lifelong_learning: Yup.string().required('Required'),
                facebook_link: Yup.string(),
                linkedin_link: Yup.string(),
                instagram_link: Yup.string(),
                twitter_link: Yup.string(),
                personal_website_link: Yup.string(),
                acceptedTerms: Yup.boolean(),
              })}
              validateOnChange
              onSubmit={handleSecondFormSubmitRequest}
            >
              {props => (
                <Form className="main-form">
                  <ConnectedFocusError />
                  {secondFormItems.map(f => LocalForm(f))}
                  <div>
                    <input
                      name="portfolio_file"
                      type="file"
                      onChange={handleFileUpload}
                      accept="application/pdf"
                    />
                    <span
                      aria-label="clear-portfolio-file"
                      tabIndex="0"
                      role="button"
                      className="cross"
                      onKeyPress={clearFileInputKey}
                      onClick={clearFileInput}
                    />
                    <br />
                    <br />
                    <progress value={progress} max="100" /> <span> {progress}%</span>
                  </div>
                  <br />
                  {privacyStartStatement}
                  <MyCheckbox name="acceptedTerms">
                    <div className="privacy">
                      {privacyStatement}{' '}
                      <a
                        rel="noreferrer noopener"
                        target="_blank"
                        href={`${localLinkResolver}/${privacyLink}`}
                      >
                        {' '}
                        {'>'}{' '}
                      </a>
                    </div>
                    <span className="text-danger">*</span>
                  </MyCheckbox>
                  <br />
                  <RequiredAlert alert_visibility={alert_visibility} />
                  <div className="d-flex flex-row mt-2">
                    <button
                      onClick={() => {
                        secondFormSaveData(props.values);
                        setVisibility_b1('d-none');
                        setVisibility_b2('d-block');
                        setVisibility_b3('d-none');
                        document.body.scrollTop = 0;
                        document.documentElement.scrollTop = 0;
                      }}
                      type="button"
                    >
                      {prevButton}
                    </button>
                    <button
                      onClick={() => {
                        secondFormSaveData(props.values);
                      }}
                      type="button"
                    >
                      {saveButton}
                    </button>
                    <button disabled={isDisabled} id="submit" type="submit">
                      {submitButton}
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Container>
        </>
      )}
    </Layout>
  );
}

export const query = graphql`
  query PortfolioPageQuery($id: String, $lang: String) {
    prismicPage(id: { eq: $id }, lang: { eq: $lang }) {
      _previewable
      type
      url
      lang
      alternate_languages {
        id
        type
        lang
        uid
      }
      data {
        page_title {
          text
        }
        body {
          ... on PrismicPageDataBodyForm {
            id
            items {
              description
              field_type
              form_type
              name
              placeholder
              radio_options
              required
              title
            }
            primary {
              next__button_label
              previous__button_label
              privacy_message
              privacy_message_starter
              privacy_page_link {
                uid
              }
              proceed_to_the_payment__button_label
              save__button_laber
              start__button_laber
            }
          }
          ... on PrismicSliceType {
            slice_type
          }
          ...PageDataBodyTable
          ...PageDataBodyText
          ...PageDataBodyQuote
          ...PageDataBodyFullWidthImage
          ...PageDataBodyImageGallery
          ...PageDataBodyImageHighlight
        }
      }
    }
    prismicMenuLink(lang: { eq: $lang }) {
      ...MenuLinkFragment
    }
  }
`;

export default withPrismicPreview(PageTemplate, [
  {
    repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
    linkResolver,
  },
]);
